
$body-background-image: url('../../images/theme/NOUT/background.jpg') !default;

$panel-bgcolor: rgba(255, 255, 255, 0.8) !default;


$panel-header-bgcolor: #80b6df !default;
$panel-header-color: white !default;

$panel-btn-bgcolor: #80b6df !default;
$panel-btn-hover-bgcolor: darken($panel-btn-bgcolor, 15%) !default; //valeur origine #449dd7
$panel-btn-color: white !default;
$panel-btn-hover-color: white !default;


$panel-footer-bgcolor: transparent !default;
$panel-footer-color: gray !default;

$screen-max-width: 767px !default;
$screen-max-height: 767px !default;
