
@font-face {
  font-family: 'DolceVita';
  src: url('../../font/Dolce Vita.ttf');
}

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-clip: border-box;
  background-size: cover;

}
#loginbox {
  height: 400px;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  margin-top: -200px;
  position: fixed;
  top: 50%;
  .languages {
    float: right;
    position: relative;
    .selected_language {
      cursor: pointer;
      &:after {
        content: "\23F7";
      }
    }
    .available_languages {
      position: absolute;
      top: calc(100% + 2px);
      border-radius: 5px;
      background: white;
      color: black;
      z-index: 3;
      display: none;
      ul {
        padding: 5px;
        list-style: none;
        li {
          cursor: pointer;
          width: 150px;
          margin-bottom: 3px;
          border-radius: 5px;
          padding: 3px;
          &:hover {
            background: #ddd;
          }
          img {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .alert {
    margin-bottom: 0;
  }
}
.panel-heading, .btn-login  {
  font-family: 'DolceVita', "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.panel-footer {
  font-size: xx-small;
}
.panel {
  border-radius: 8px;
}
.panel-heading {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

}

.custom_logo_title {
  position: absolute;
  left: -250px;
  top: -200px;

  .custom_logo {
    position: absolute;
    height: 125px;
    width: 500px;
    left: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .custom_title {
    font-family: 'DolceVita', "Helvetica Neue",Helvetica,Arial,sans-serif;
    position: absolute;
    height: 75px;
    width: 500px;
    top: 125px;
    left: 250px;
    text-align: center;
  }
}

#reset-password {
  cursor: default;
  font-size: smaller;
}

@media only screen and (max-width: $screen-max-width) /*and (orientation: portrait),
only screen and (max-height: $screen-max-height)and (orientation: landscape)*/{
  #loginbox {
    width: 95vw;
    margin: auto;
    left: auto;
    top: 25%;
  }
}
